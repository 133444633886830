import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Header from '../components/Header/SimpleHeader'
import DeepLinkRedirection from '../components/DeepLinkRedirection'
import Footer from '../components/Footer'

export default function EvsePage() {
  let params = useParams()
  const { t } = useTranslation()

  const code = params.code
  const deepLink = `foresta://foresta.energy/explore/evses/${code}`

  return (
    <>
      <Header />
      <DeepLinkRedirection
        deepLink={deepLink}
        title={t('station.title')}
        subtitle={t('station.subtitle')}
      />
      <Footer />
    </>
  )
}
